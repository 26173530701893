.navbar {
  width: 100%;
  height: 80px;
  background: #38599b;
  font-family: "garamond";
}

.links {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.navbar a {
  color: white;
  text-decoration: none;
  margin: 20px;
  font-size: 25px;
}