.home {
  width: 100%;
  align-items: center;
  font-family: "Segoe UI", sans-serif;
  color: #3e497a;
}

.about {
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  text-align: center;
  background-image: url("../../images/HomeBanner.png");
  background-size: 100vw;
  background-repeat: no-repeat;
  color: #f0f0f0;
}

.about h2 {
  width: 60%;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 7vw;
  color: #8dc9fe;
  height: 10%;
}

.about .prompt {
  width: 60%;
  font-size: 2vw;
  text-shadow: 2px 2px 3px #5282ba;
  height: 90%;
  padding-top: 3%;
  margin-top: 3vw;
}

.prompt svg {
  font-size: 4vw;
  margin: 5px;
  color: white;
  text-shadow: 2px 2px 5px #3e497a;
}

.skills {
  font-size: 3vw;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 2%;
}

.skills h1 {
  color: #3e497a;
  font-size: 3.5vw;
}

.skills h2 {
  color: #3e497a;
  font-size: 2.7vw;
  margin-bottom: 0;
}

.skills .list {
  list-style: none;
  width: 60%;
  margin: 0;
}

.list span {
  font-size: 1.7vw;
}